var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    { staticClass: "status-btn", on: { click: _vm.data.click } },
    [
      _c("div", { staticClass: "img-squer" }),
      _c("span", { domProps: { textContent: _vm._s(_vm.$t("Stop")) } }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }